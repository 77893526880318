import styled from "styled-components"

export const Services = styled.div`
  width: 100%;
  padding: 20px;
  display: grid; 
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
  gap: 1rem;
  ${({ theme }) => theme.md`
  grid-template-columns: repeat(1, minmax(0, 1fr)); 

  `}
`
export const ItemContainer = styled.div`
  width: 100%;
  display : flex;
  ${({ theme }) => theme.md`
  justify-content: left;
  `}
`

export const ServicesItem = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 15px;
  clear: both;
  ${({ theme }) => theme.md`
  padding-right: 40px;
  `}
`

export const ServiceTitle = styled.h3`
  color: #22252e;
  font-family: Lato;
  font-weight: 900;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.8px;
  margin: 0 0 15px;

  ${({ theme }) => theme.md`
    margin-bottom:5px;
  `}
`

export const ServiceDescription = styled.p`
  font-family: Lato;
  line-height: 26px;
  color: #4e4e4e;
  font-size: 17px;
  font-weight: 400;
  margin: 0px 50px 0px 0px;
  text-align: justify;
  text-justify: inter-word;
  ${({ theme }) => theme.md`
  margin-right: 0px
  `}
`
export const ServiceIcon = styled.img`
  width: 70px;
  background: white;
  margin-bottom: 20px;
  ${({ theme }) => theme.md`
    width: 50px;
    margin:0 0 10px;
    position: relative;
    left:0 !important;
    top:0 !important;
    right:auto !important;
  `}
`
export const OurExpertise = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  padding-top: 20px;
`

export const ExpertiseItem = styled.div`
  width: 50%;
  padding-bottom: 40px;
  box-sizing: border-box;
  &:nth-child(odd) {
    padding-right: 5%;
  }
  &:nth-child(even) {
    padding-left: 5%;
  }

  ${({ theme }) => theme.sm`
    width: 100%;
    padding:0 0 10px !important;
  `}
`
export const ExpertiseTitle = styled.h2`
  position: relative;
  font-size: 18px;
  margin: 0;
  padding-top: 15px;
  font-family: Lato;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;

  ${({ theme }) => theme.sm`
    font-size: 18px;
  `}
`

export const ExpertiseDescription = styled.p`
  color: rgba(255, 255, 255, 0.6);
  line-height: 170%;
  font-size: 17px;
`

export const OurMethods = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
`

export const MethodsItem = styled.div`
  width: 30%;
  padding-bottom: 30px;
  ${({ theme }) => theme.sm`
    width: 100%;
    padding-bottom: 50px;
  `}
`
export const MethodsTitle = styled.h2`
  font-family: Lato;
  font-weight: 900;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.8px;
  margin: 0 0 15px;

  ${({ theme }) => theme.sm`
   padding-left: 60px;
  `}
`
export const MethodsDescription = styled.p`
  font-family: Lato;
  color: #4e4e4e;
  text-align: justify;
  text-justify: inter-word;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
`
export const MethodsIcon = styled.img`
  display: block;
  width: 60px;
  margin-bottom: 30px;
  ${({ theme }) => theme.sm`
    width: 42px;
    position: absolute;
    left: -2px;
    margin: 0;
    top: -5px;
  `}
`

export const OurExpertiseImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.05;
`
