import React from "react"
import {
  Services,
  ServicesItem,
  ServiceIcon,
  ServiceTitle,
  ServiceDescription,
  ItemContainer,
} from "../styles/whatwedo"
import consultancy from "../images/services/consultancy-1.svg"
import training3 from "../images/services/training-1.svg"
import interactive from "../images/services/interactive-1.svg"
import xtalents from "../images/services/xtalents-1.svg"
import ScrollAnimation from "react-animate-on-scroll"

const OurServices = () => (
  <Services>
    <ScrollAnimation
      animateOnce={true}
      delay={0}
      duration={0.5}
      animateIn="slideInUp"
      >
      <ItemContainer >
        <ServicesItem>
          <ServiceIcon src={consultancy} />
          <ServiceTitle>Digital delivery acceleration </ServiceTitle>
          <ServiceDescription>
          We take the lead of your project through all phases. From design until the successful launch of your solution.
          </ServiceDescription>
        </ServicesItem>
      </ItemContainer>
      </ScrollAnimation>
      <ScrollAnimation
      animateOnce={true}
      delay={0}
      duration={0.5}
      animateIn="slideInUp"
      >
      <ItemContainer >
        <ServicesItem>
            <ServiceIcon src={interactive} />
            <ServiceTitle>Consulting </ServiceTitle>
            <ServiceDescription>
            We help you design your strategy to quickly succeed your digital transformation 
            and deliver value to the market through modern technologies.            
             </ServiceDescription>
        </ServicesItem>
      </ItemContainer>
      </ScrollAnimation>
      <ScrollAnimation
      animateOnce={true}
      delay={0}
      duration={0.5}
      animateIn="slideInUp"
      >
      <ItemContainer >
        <ServicesItem>
            <ServiceIcon src={training3} />

            <ServiceTitle>Training</ServiceTitle>
            <ServiceDescription>
            Designing the most relevant training and transfer of knowledge for your team after the
             delivery of your project.
            </ServiceDescription>
        </ServicesItem>
      </ItemContainer>
      </ScrollAnimation>
      <ScrollAnimation
      animateOnce={true}
      delay={0}
      duration={0.5}
      animateIn="slideInUp"
      >
      <ItemContainer >
        <ServicesItem>
            <ServiceIcon src={xtalents} />

            <ServiceTitle>xPerts</ServiceTitle>
            <ServiceDescription>
            We provide the best and most adaptable IT experts to help you with your digital challenges and reinforce your teams and in-house capabilities.
            </ServiceDescription>
        </ServicesItem>
      </ItemContainer>
      </ScrollAnimation>
  </Services>

  // <Services>
  //   <ServicesItem>
  //     <ServiceInner>
  //       <ScrollAnimation
  //         animateOnce={true}
  //         delay={0}
  //         duration={0.5}
  //         animateIn="fadeInRight"
  //       >
  //         <ServiceIcon src={consultancy} />
  //         <ServiceNum>01</ServiceNum>
  //         <ServiceTitle>
  //           IT CONSULTANCY <br />& EXPERTISE
  //         </ServiceTitle>
  //         <ServiceDescription>
  //           We help you design your digital strategy, asses your infrastructure,
  //           process and methodology capabilities. We assist you through your
  //           digital transformation from inception to launch.
  //         </ServiceDescription>
  //       </ScrollAnimation>
  //     </ServiceInner>
  //   </ServicesItem>

  //   <ServicesItem>
  //     <ServiceInner>
  //       <ScrollAnimation
  //         animateOnce={true}
  //         delay={0}
  //         duration={0.5}
  //         animateIn="fadeInRight"
  //       >
  //         <ServiceIcon src={interactive} />
  //         <ServiceNum>02</ServiceNum>
  //         <ServiceTitle>Technology acceleration</ServiceTitle>
  //         <ServiceDescription>
  //           Whether it is a company turnkey IT project, a Proof of concept, a
  //           startup MVP Our agility conducted by long date practitioners will
  //           ensure the success of your ventures in the quickest and most
  //           relevant way. We take in charge your project through all phases
  //           starting from design workshops through deployment, concept testing
  //           till the successful launch of your services, products and or
  //           solutions.
  //         </ServiceDescription>
  //       </ScrollAnimation>
  //     </ServiceInner>
  //   </ServicesItem>

  //   <ServicesItem>
  //     <ServiceInner>
  //       <ScrollAnimation
  //         animateOnce={true}
  //         delay={0}
  //         duration={0.5}
  //         animateIn="fadeInLeft"
  //       >
  //         <ServiceIcon src={xtalents} />
  //         <ServiceNum>04</ServiceNum>
  //         <ServiceTitle>
  //           XTALENTS <br />& XPERTS
  //         </ServiceTitle>
  //         <ServiceDescription>
  //           We put at your disposal the best and most adaptable IT talents and
  //           experts to help you with your transient capabilities’ dilemmas.
  //         </ServiceDescription>
  //       </ScrollAnimation>
  //     </ServiceInner>
  //   </ServicesItem>

  //   <ServicesItem>
  //     <ServiceInner>
  //       <ScrollAnimation
  //         animateOnce={true}
  //         delay={0}
  //         duration={0.5}
  //         animateIn="fadeInLeft"
  //       >
  //         <ServiceIcon src={training3} />
  //         <ServiceNum>03</ServiceNum>
  //         <ServiceTitle>TRAINING</ServiceTitle>
  //         <ServiceDescription>
  //           The need to train your teams had never been so urgent and complex.
  //           Technology is moving at a confusing pace. We are tech addicts and
  //           our experts, from our company and network will help you designing
  //           and delivering the right and most relevant training to empower and
  //           prepare your teams to face the tech revolution.
  //         </ServiceDescription>
  //       </ScrollAnimation>
  //     </ServiceInner>
  //   </ServicesItem>
  // </Services>
)

export default OurServices
